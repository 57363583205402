<template>
  <v-dialog v-model="viewDialog" max-width="500" persistent scrollable>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>View Detail</v-card-title>
      <v-card-text>
        <div class="mylist">
          <ul>
            <li>
              <div class="myleft">service name</div>
              <div class="myright">{{ view.service }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <div class="myleft">description</div>
              <div class="myright">{{ view.description }}</div>
              <div class="clearall"></div>
            </li>
            <li>
              <v-img
                  :src="view.image"
                  contain
                  max-width="300"
                  style="margin: auto"
              ></v-img>
            </li>
          </ul>
        </div>
        <br/>
      </v-card-text>
      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
//   import moment from "moment";
//   import Utils from "./../../../../mixins/utils";
export default {
  props: {
    viewDialog: Boolean,
    myId: {type: Number, default: 0},
    name: {type: String, default: null},
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      loading: false,
      view: {service: null, description: null, image: null},
    };
  },
  created() {
    this.find();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers.services
          .find(this.myId)
          .then((response) => {
            console.log(response.data);
            this.view.service = response.data.service;
            this.view.description = response.data.description;
            this.view.image = response.data.image;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {id: this.myId, state: false};
      this.$emit("closeView", payload);
    },
  },
};
</script>
